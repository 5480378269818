* {
  box-sizing: border-box;
}

.no-scroll {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.shadow-bottom {
  box-shadow: inset 0px -1em 1em -1em rgb(0 0 0 / 25%) !important
}

@media print {
  .kipu-ensure-table-break-word {
    // adding extra specificity to override any other styles
    table tr td {
      word-break: break-word !important
    }
  }
}
